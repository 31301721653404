import styled from 'styled-components';
import { device } from '../device';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerTitle = styled.h2`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4.375rem;
  margin-bottom: 3.125rem;

  @media ${device.laptop} {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  @media ${device.mobile} {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.875rem;
  color: #231f20;

  @media ${device.mobile} {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
`;

export const ContainerMovies = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 1.8125rem;
  margin-bottom: 4.375rem;

  @media ${device.laptop} {
    flex-wrap: nowrap;
    flex-direction: row;
    overflow-x: scroll;
    margin-bottom: 1.875rem;

    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media ${device.mobile} {
    margin-bottom: 1.25rem;
  }
`;

export const ContainerIframe = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 16.875rem;
  min-width: 16.875rem;
`;

export const Movies = styled.iframe`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 9.375rem;
  border: none;
`;

export const TitleMovie = styled.p`
  display: flex;
  flex-direction: column;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.625rem;
  color: #231f20;

  @media ${device.laptop} {
    font-size: 0.875rem;
  }
`;
