import React from 'react';
import {
  Container,
  ContainerTitle,
  Title,
  ContainerMovies,
  Movies,
  TitleMovie,
  ContainerIframe,
} from './style';

export function VideoGallery({ markdown }) {
  const title = markdown[0].datafragment[0].title;
  const content = markdown[0].datafragment[0].movies;

  return (
    <Container>
      <ContainerTitle>
        <Title>{title}</Title>
      </ContainerTitle>
      <ContainerMovies>
        {content.length > 0 &&
          content !== undefined &&
          content.map((el, index) => (
            <ContainerIframe key={index}>
              <Movies
                src={el.src}
                allow={el.allow}
                allowFullScreen={el.allowFullScreen}
              />
              <TitleMovie>{el.title}</TitleMovie>
            </ContainerIframe>
          ))}
      </ContainerMovies>
    </Container>
  );
}
